import { GraphQLClient, prepareClient, SITEWIDE_CONTENT } from "@telia/b2x-graphql-request";
import { IconName } from "@teliads/components/icons/main/Icons";

import { getHeroContent, HeroContent } from "./hero";
import { getPageContent } from "./page";
import { getQaContent, QaContent } from "./qa";
//const __API__ = "/.api/sitewide-content/graphql";
const __API__ = "/.api";
export const contentClient: GraphQLClient = prepareClient(__API__, SITEWIDE_CONTENT);

export interface Teaser {
  mainTitle?: string;
  uspData: unknown;
  uniqueSellingPoints: unknown;
  imageWomanLookingAtLaptop?: string;
}

export interface Image {
  url: string;
  description?: string | null;
}

export interface CallToAction {
  text?: string | null;
  type?: string | null;
  linkTrigger?: string | null;
}

export interface UniqueSellingPoint {
  id: string;
  title?: string;
  description?: string;
  icon?: IconName;
}

export interface TeaserListBase {
  id: string | undefined;
  title: string | undefined;
  richPreamble: string | undefined | null;
}

export interface TeaserContentElement extends TeaserListBase {
  uniqueSellingPoints: UniqueSellingPoint[];
  embedMedia: { id?: string; url?: string };
  callToAction: CallToAction[];
  primaryMediaImage: { image: Image } | undefined | null;
  title: string;
}

export interface PageContent {
  uspContent: TeaserContentElement;
  teaserContent: TeaserContentElement;
  createAccountContent: TeaserContentElement;
}
export type ContentError = {
  error: boolean;
  message?: string;
};

interface ContentfulError {
  response: { message: unknown };
}
export const getContent = async (
  locale: string
): Promise<{
  heroContent?: HeroContent;
  pageContent?: PageContent;
  qaContent?: QaContent;
  contentError?: { error: boolean; message: string };
}> => {
  try {
    // TODO: these are run in sequence. Consider Promise.all or Promise.allSettled?
    const heroContent = await getHeroContent(locale);
    const pageContent = await getPageContent(locale);
    const qaContent = await getQaContent(locale);
    return { heroContent, pageContent, qaContent };
  } catch (error: unknown) {
    console.log(error);
    const message = (error as ContentfulError).response?.message ?? "No error message received.";
    return {
      contentError: { error: true, message: JSON.stringify(message) },
    };
  }
};
