import React from "react";
import styled from "styled-components";
import color from "@teliads/components/foundations/colors/variables.js";
import spacing from "@teliads/components/foundations/spacing/variables.js";
import {
  TeliaButton,
  TeliaCol,
  TeliaGrid,
  TeliaHeading,
  TeliaImage,
  TeliaP,
  TeliaRow,
} from "@teliads/components/react";

export type ErrorMessageProps = {
  message: {
    heading: string;
    text: string;
    button: string;
  };
};

const TeliaBackgroundColor = styled("div")`
  display: flex;
  flex-grow: 1;
  background-color: ${color.gray50};
`;

const TeliaImageCenteredMaxHeight = styled(TeliaImage)`
  .telia-image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: ${spacing.spacing48};
  }

  img {
    max-height: 25rem;
  }
`;

const TeliaRowTextCenter = styled(TeliaRow)`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const ErrorMessage = ({ message }: ErrorMessageProps) => {
  return (
    <TeliaBackgroundColor id="error-wrapper">
      <TeliaGrid>
        <TeliaRow>
          <TeliaCol width={12} className="top-spacing-64">
            <TeliaImageCenteredMaxHeight src="/500-error.svg" dataTestid="error-image" />
          </TeliaCol>
        </TeliaRow>

        <TeliaRowTextCenter>
          <TeliaCol width={12} className="top-spacing-32">
            <TeliaHeading data-testid="error-message-heading" tag="h1" variant="title-400">
              {message.heading}
            </TeliaHeading>
          </TeliaCol>
        </TeliaRowTextCenter>

        <TeliaRowTextCenter>
          <TeliaCol width={12} className="top-spacing-16">
            <TeliaP variant="preamble-100" data-testid="error-message-text">
              {message.text}
            </TeliaP>
          </TeliaCol>
        </TeliaRowTextCenter>

        <TeliaRowTextCenter>
          <TeliaCol
            width={12}
            className="top-spacing-32 bottom-spacing-96"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TeliaButton
              data-testid="error-message-button"
              allyLabel={message.button}
              leftIcon={{
                name: "sync",
                size: "sm",
                allyTitle: "Sync Icon",
                dataTestid: "sync-icon",
              }}
              type="button"
              variant="primary"
              size="md"
              text={message.button}
              style={{ alignSelf: "center" }}
              onClick={() => window.location.reload()}
            />
          </TeliaCol>
        </TeliaRowTextCenter>
      </TeliaGrid>
    </TeliaBackgroundColor>
  );
};

export default ErrorMessage;
