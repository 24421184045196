import {
  borders,
  breakpoints,
  colors,
  grid,
  layers,
  shadows,
  spacing,
} from "@teliads/components/foundations";

export const theme = {
  voca: { ...colors, ...borders, ...breakpoints, ...spacing, ...grid, ...shadows, layers },
  local: {
    fluidBreakpointMd: "60rem",
    fluidBreakpointXl: "144rem",
    breakpointLgMax: "1440px",
    breakpointLgMin: "1081px",
    breakpointMdMax: "1080px",
    breakpointMdMin: "841px",
    breakpointSmMax: "840px",
    breakpointSmMin: "601px",
    breakpointXlgMax: "1920px",
    breakpointXlgMin: "1441px",
    breakpointXsMax: "600px",
    breakpointXsMin: "481px",
    breakpointXxlgMin: "1921px",
    breakpointXxsMax: "480px",
    breakpointXxsMin: "376px",
    breakpointXxxsMax: "375px",
    breakpointXxxsMin: "0",
  },
} as const;
