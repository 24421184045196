import React, { memo, Suspense } from "react";
import { ThemeProvider } from "styled-components";
import { registerIconsBundle } from "@teliads/icons";

import "../../styles/common-styles.scss";
import "../../styles/styles.css";
import App from "../app";
import LandingSkeleton from "../landing-skeleton";
import { theme } from "../theme";

function Scaffolding() {
  registerIconsBundle();

  return (
    <Suspense fallback={<LandingSkeleton />}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Suspense>
  );
}

export default memo(Scaffolding);
