import React, { memo } from "react";
import { useMessage } from "@messageformat/react";
import { TeliaGrid, TeliaSkeleton, TeliaVisuallyHidden } from "@teliads/components/react/commonjs";

import Row from "../row";

type AriaTextProps = {
  message: {
    ariaText: string;
  };
};

const SkeletonLoader = ({ message }: AriaTextProps) => {
  return (
    <>
      <TeliaVisuallyHidden> {message.ariaText} </TeliaVisuallyHidden>
      <TeliaSkeleton style={{ height: "67rem", width: "100%", marginBottom: "1rem" }}>
        Loading...
      </TeliaSkeleton>
      <TeliaGrid>
        <Row>
          <TeliaVisuallyHidden> {message.ariaText} </TeliaVisuallyHidden>
          <TeliaSkeleton style={{ height: "27rem", width: "100%", marginBottom: "1rem" }}>
            Loading...
          </TeliaSkeleton>
        </Row>
        <Row>
          <TeliaVisuallyHidden> {message.ariaText} </TeliaVisuallyHidden>
          <TeliaSkeleton style={{ height: "46rem", width: "100%", marginBottom: "1rem" }}>
            Loading...
          </TeliaSkeleton>
        </Row>
      </TeliaGrid>
    </>
  );
};

function LandingSkeleton() {
  return <SkeletonLoader message={{ ariaText: useMessage("B2B_LANDING_PAGE.content-loading") }} />;
}

export default memo(LandingSkeleton);
