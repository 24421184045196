import { memo } from "react";
import React from "react";
import { useMessage } from "@messageformat/react";

import ErrorMessage from "../error-message";

const LandingErrorMessage = () => {
  return (
    <ErrorMessage
      message={{
        heading: useMessage("content-error.heading"),
        text: useMessage("content-error.text"),
        button: useMessage("content-error.button-text"),
      }}
    />
  );
};

export default memo(LandingErrorMessage);
