import { useEffect, useState } from "react";

interface Dimension {
  width?: number;
  height?: number;
}

export enum Breakpoint {
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
}
export const BreakpointsNumber = {
  sm: 0,
  md: 678,
  lg: 1200,
  xl: 1440,
};

interface WindowSizeResult {
  dimension: Dimension;
  breakpoint?: Breakpoint;
  isSmall: boolean;
}

export function useWindowSize(): WindowSizeResult {
  const [windowSize, setWindowSize] = useState<Dimension>({
    width: undefined,
    height: undefined,
  });

  const [breakpoint, setBreakpoint] = useState<Breakpoint>();

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      return window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowSize.width) {
      if (0 <= windowSize.width && windowSize.width <= BreakpointsNumber.md) {
        setBreakpoint(Breakpoint.sm);
      } else if (windowSize.width <= BreakpointsNumber.lg) {
        setBreakpoint(Breakpoint.md);
      } else if (windowSize.width <= BreakpointsNumber.xl) {
        setBreakpoint(Breakpoint.lg);
      } else {
        setBreakpoint(Breakpoint.xl);
      }
    }
  }, [windowSize]);

  return {
    dimension: windowSize,
    breakpoint: breakpoint,
    isSmall: Breakpoint.sm <= (breakpoint ?? 0),
  };
}
