import { gql } from "graphql-request";

import { CallToAction, contentClient, Image, TeaserListBase } from "./contentful";

export interface HeroContent extends TeaserListBase {
  primaryMediaImage: { image: Image } | undefined | null;
  callToAction: CallToAction[] | undefined;
}

export const HERO_CONTENT = gql`
  query getHeroContent($readable_id: String!, $locale_id: Locale) {
    teaserWrapper(identifier: { key: READABLE_ID, value: $readable_id }, locale: $locale_id) {
      teaserList {
        id
        title
        richPreamble
        primaryMediaImage {
          image {
            url
            description
          }
        }
        callToAction {
          text
          type
          linkTrigger
        }
      }
    }
  }
`;

export const HERO_CONTENT_ID = "mybusiness-landing-page-hero";

export async function getHeroContent(locale: string): Promise<HeroContent> {
  const response = await contentClient.request<GraphQL.GetHeroContentQuery>(HERO_CONTENT, {
    readable_id: HERO_CONTENT_ID,
    locale_id: locale,
  });
  const teaserData = response.teaserWrapper;
  const heroElement = teaserData?.teaserList[0];

  return {
    id: heroElement?.id,
    title: heroElement?.title,
    richPreamble: heroElement?.richPreamble,
    primaryMediaImage: heroElement?.primaryMediaImage,
    callToAction: heroElement?.callToAction,
  };
}
