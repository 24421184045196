import React, { lazy, memo, useEffect, useState } from "react";
import { MessageObject, MessageProvider } from "@messageformat/react";
import { currentLanguage, currentLocale } from "@telia/b2b-i18n";
import { logError } from "@telia/b2x-logging";

import { getContent } from "../api/contentful";
import { HeroContent } from "../api/hero";
import { PageContent } from "../api/page";
import { QaContent } from "../api/qa";
import { PACKAGE_NAME } from "../utils/constants/environment";
import Hero from "./hero";
import LandingErrorMessage from "./landing-error-mesage";
import LandingSkeleton from "./landing-skeleton";

const LazyUspGrid = lazy(() => import("./usp-grid"));
const LazyContentBanner = lazy(() => import("./content-banner"));
const LazyCreateAccount = lazy(() => import("./create-account"));
const LazyFaq = lazy(() => import("./faq"));

interface ContentfulState {
  heroContent?: HeroContent;
  pageContent?: PageContent;
  qaContent?: QaContent;

  contentError?: { error: boolean; message: string };
}

function App() {
  const [contentfulData, setContentfulData] = useState<ContentfulState>();
  const [messages, setMessages] = useState<MessageObject>({});

  useEffect(() => {
    (async () => {
      const messages = await import(`../locales/locale.${currentLanguage()}.yaml`);
      setMessages(messages.default);
    })();

    getContent(currentLocale())
      .then((data: ContentfulState) => {
        setContentfulData(data);
      })
      .catch((reason: string) => logError(PACKAGE_NAME, reason));
  }, []);

  if (!contentfulData) {
    return <LandingSkeleton />;
  }

  if (contentfulData?.contentError) {
    logError(
      PACKAGE_NAME,
      `Could not get content. ${contentfulData.contentError?.message}`
    ).finally();
  }

  return (
    <MessageProvider locale={currentLanguage()} messages={messages}>
      {contentfulData.contentError ? (
        <LandingErrorMessage />
      ) : (
        <div data-testid="b2b-landing-page-root" id="landingPage">
          <Hero content={contentfulData?.heroContent} />
          <LazyUspGrid content={contentfulData?.pageContent?.uspContent} />
          <LazyContentBanner content={contentfulData?.pageContent?.teaserContent} />
          <LazyCreateAccount content={contentfulData?.pageContent?.createAccountContent} />
          <LazyFaq content={contentfulData?.qaContent} />
        </div>
      )}
    </MessageProvider>
  );
}

export default memo(App);
