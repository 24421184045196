import { gql } from "graphql-request";

import { contentClient } from "./contentful";

export interface QaPair {
  id: string;
  question: string;
  answer?: string | null;
}

export interface QaContent {
  heading?: string;
  items: QaPair[];
}

export const QA_CONTENT_ID = "fragor-och-svar-om-mybusiness";

export const QA_CONTENT = gql`
  query getQaContent($readable_id: String!, $locale_id: Locale) {
    qaWrapper(identifier: { key: READABLE_ID, value: $readable_id }, locale: $locale_id) {
      id
      title
      questions {
        id
        title
        body
      }
    }
  }
`;

// TODO:  implement dynamic language support via toggle in navbar
export async function getQaContent(locale: string): Promise<QaContent> {
  const response = await contentClient.request<GraphQL.GetQaContentQuery>(QA_CONTENT, {
    readable_id: QA_CONTENT_ID,
    locale_id: locale,
  });

  const qaData = response.qaWrapper;
  const items: QaPair[] | undefined = qaData?.questions?.map((qa) => ({
    id: qa.id,
    question: qa.title,
    answer: qa.body,
  }));

  return {
    heading: qaData?.title,
    items: items ?? [],
  };
}
