import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { useMessage } from "@messageformat/react";
import {
  getGa4OrganisationRegistrationParams,
  getGa4StepParams,
  trackGa4,
} from "@telia/b2b-analytics";
import { action, category, trackEventOpenPage } from "@telia/b2b-web-analytics-wrapper";
import { croppedImageUrl } from "@telia/b2x-image-api";
import { breakpoints } from "@teliads/components/foundations";
import {
  TeliaCol,
  TeliaCtaLink,
  TeliaGrid,
  TeliaHeading,
  TeliaP,
  TeliaRow,
  TeliaTextSpacing,
} from "@teliads/components/react";

import { HeroContent } from "../../api/hero";
import { Breakpoint, useWindowSize } from "../../hooks/useWindowSize";
import { LOGIN_URL, REGISTER_URL, TELIA_DOMAIN } from "../../utils/constants/urls";
import { getPreambleAsString } from "../../utils/utils";

export type HeroProps = {
  content?: HeroContent;
};

const HeroContainer = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  overflow: hidden;
  min-height: 40rem;
  height: calc(100vh - 64px - 15rem);
`;

const horizontalLinearGradient =
  "linear-gradient(90deg, rgba(0, 0, 0, 0.6) 67%, rgba(0, 0, 0, 0) 100%)";

const verticalLinearGradient =
  "linear-gradient(0deg, rgba(0, 0, 0, 0.6) 38%, rgba(0, 0, 0, 0) 100%)";

const DynamicImageOverlay = styled.div<{ breakpoint?: string }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: ${(props) => (props.breakpoint === Breakpoint.sm ? "100vw" : "60vw")};
  z-index: 1;
  background: ${(props) =>
    props.breakpoint === Breakpoint.sm ? verticalLinearGradient : horizontalLinearGradient};
`;

const HeroImage = styled.img`
  object-fit: cover;
  flex-grow: 1;
  margin-top: -15vw;
`;

const FlexTeliaCol = styled(TeliaCol)`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: ${breakpoints.breakpointMd}) {
    flex-direction: row;
  }
`;

const ContentWrapper = styled.div`
  z-index: 9;
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: flex-end;
`;

function Hero({ content }: HeroProps) {
  const croppedUrl = croppedImageUrl(
    `${TELIA_DOMAIN}${content?.primaryMediaImage?.image.url}`,
    1280,
    1024,
    { fm: "webp", q: 90, f: "faces" }
  );
  const alt = content?.primaryMediaImage?.image.description ?? "";
  const title = content?.title ?? "";
  const registerButtonText = content?.callToAction?.[1]?.text ?? "";
  const loginButtonText = content?.callToAction?.[0]?.text ?? "";
  const invokingLoginText = useMessage("hero.invokingLogin");
  const [invokingLogin, setInvokingLogin] = useState<boolean>();
  const [invokingRegister, setInvokingRegister] = useState<boolean>();
  const { dimension, breakpoint, isSmall } = useWindowSize();

  const handleLoginEvent = useCallback(() => {
    setInvokingLogin(true);
    trackEventOpenPage(
      category.MB_INLOGGNING,
      action.INITIATED,
      "Logga in med användarnamn"
    ).finally();
    window.location.assign(LOGIN_URL);
  }, []);

  const handleRegisterEvent = useCallback(() => {
    setInvokingRegister(true);

    trackEventOpenPage(category.MB_INLOGGNING, action.INITIATED, "create_account_hero").finally();

    trackGa4("account_registration_initiate", {
      ...getGa4OrganisationRegistrationParams("default"),
      ...getGa4StepParams("account_registration_initiate", 1, "create_account"),
    });

    window.location.assign(REGISTER_URL);
  }, []);

  return (
    <HeroContainer aria-label="Section with an image, descriptive texts and two buttons that links two the log in page and create account page">
      <DynamicImageOverlay breakpoint={breakpoint} />
      <HeroImage src={croppedUrl} alt={alt} />
      <ContentWrapper>
        <TeliaGrid>
          <TeliaRow className="bottom-spacing-16">
            <TeliaCol width={12}>
              <TeliaTextSpacing>
                <TeliaHeading tag="h1" variant="display-100" className="color-white">
                  {title}
                </TeliaHeading>
              </TeliaTextSpacing>
            </TeliaCol>
          </TeliaRow>

          <TeliaRow>
            <TeliaCol width={12} widthLg={4} widthMd={6} class="bottom-spacing-32">
              <TeliaTextSpacing>
                <TeliaP disableHyphenation variant="preamble-100" className="color-white">
                  {getPreambleAsString(content)}
                </TeliaP>
              </TeliaTextSpacing>
            </TeliaCol>
          </TeliaRow>

          <TeliaRow>
            <FlexTeliaCol width={12} className="bottom-spacing-32">
              <TeliaCtaLink
                tabIndex={0}
                size="lg"
                data-testid="hero-login-button"
                className={`bottom-spacing-16 ${
                  (dimension.width ?? 0) >= 600 && "right-spacing-8"
                }`}
                variant="expressive"
                fullWidth={isSmall}
                onClick={() => {
                  if (!invokingLogin) {
                    handleLoginEvent();
                  }
                }}
              >
                {invokingLogin ? invokingLoginText : loginButtonText}
              </TeliaCtaLink>
              <TeliaCtaLink
                tabIndex={0}
                size="lg"
                data-testid="hero-register-button"
                variant="secondary-white"
                fullWidth={isSmall}
                linkTitle={registerButtonText}
                onClick={() => {
                  if (!invokingRegister) {
                    handleRegisterEvent();
                  }
                }}
              >
                {registerButtonText}
              </TeliaCtaLink>
            </FlexTeliaCol>
          </TeliaRow>
        </TeliaGrid>
      </ContentWrapper>
    </HeroContainer>
  );
}

export default memo(Hero);
