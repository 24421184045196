import React, { memo, PropsWithChildren } from "react";

export type RowProps = PropsWithChildren<unknown>;

const Row = (props: RowProps) => {
  const { children } = props;
  return <div className="top-spacing-96 bottom-spacing-96">{children}</div>;
};

export default memo(Row);
