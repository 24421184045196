import { gql } from "graphql-request";

import { contentClient, TeaserContentElement } from "./contentful";

export const PAGE_CONTENT_ID = "mybusiness-landing-page-banners";

export interface PageContent {
  uspContent: TeaserContentElement;
  teaserContent: TeaserContentElement;
  createAccountContent: TeaserContentElement;
}

export const PAGE_CONTENT = gql`
  query getPageContent($readable_id: String!, $locale_id: Locale) {
    teaserWrapper(identifier: { key: READABLE_ID, value: $readable_id }, locale: $locale_id) {
      teaserList {
        id
        title
        richPreamble
        uniqueSellingPoints {
          id
          title
          description
          icon
        }
        embedMedia {
          id
          url
        }
        callToAction {
          text
          type
          linkTrigger
        }
        primaryMediaImage {
          image {
            url
            description
          }
        }
      }
    }
  }
`;

export async function getPageContent(locale: string): Promise<PageContent> {
  const response = await contentClient.request<GraphQL.GetPageContentQuery>(PAGE_CONTENT, {
    readable_id: PAGE_CONTENT_ID,
    locale_id: locale,
  });

  const teaserData = response.teaserWrapper;
  if (!teaserData?.teaserList || teaserData?.teaserList.length < 3) {
    throw new Error("Did not get expected Page content");
  }

  const uspElement = teaserData?.teaserList[0] as TeaserContentElement;
  const teaserElement = teaserData?.teaserList[1] as TeaserContentElement;
  const crateAccountElement = teaserData?.teaserList[2] as TeaserContentElement;

  return {
    uspContent: uspElement,
    teaserContent: teaserElement,
    createAccountContent: crateAccountElement,
  };
}
