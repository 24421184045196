import { logError } from "@telia/b2x-logging";

import { HeroContent } from "../api/hero";
import { CreateAccountContent } from "../components/create-account";
import { PACKAGE_NAME } from "./constants/environment";

export function getPreambleAsString(content?: CreateAccountContent | HeroContent): string {
  if (!content || !content.richPreamble) {
    return "";
  }

  try {
    const preambleInJsonFormat = JSON.parse(content.richPreamble);
    return preambleInJsonFormat?.content[0].content[0].value;
  } catch (error: unknown) {
    logError(PACKAGE_NAME, `Invalid preamble JSON received in content ${error}`).finally();
    return "";
  }
}
